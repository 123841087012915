.App {
  text-align: center;
  min-height: 100dvh;
  display: flex;
  overflow: hidden;
  position: relative;
}

.bg-focus-dark {
  position: absolute;
  background-color: var(--bg-focus-pc);
  width: 100%;
  height: 100dvh;
  left: 0px;
  z-index: 5;
}

/* @media (max-width: 960px) {
  .bg-focus-dark {
    background-color: var(--bg-focus-mobile);
  }
} */
