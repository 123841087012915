.input-main {
  margin: auto;
  position: absolute;
  bottom: 5px;
  max-width: 830px;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: var(--chat-section-bg);
  padding: 15px 0px 15px;
  animation: widthChange 1s ease-out;
}

.input-main input {
  width: 100%;
  height: 65px;
  padding-left: 25px;
  padding-right: 60px;
  font-size: 16px;
  line-height: 24px;
  color: var(--input-font-color);
  background-color: var(--input-bg);
  border: 0px;
  border-radius: 10px;
}

.input-main input:focus {
  outline: none;
  background-color: var(--input-focus-bg);
}

.input-main input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--input-placeholder-font-color);
}

.input-main button {
  background-color: transparent;
  border: 0px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}


@keyframes widthChange {
  from {
    width: 10%;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 1106px) {
  .input-main {
    padding: 10px 20px 15px;
  }

  .input-main button {
    right: 25px;
  }
}

@media (max-width: 680px) {
  .input-main {
    bottom: -4px;
    padding: 15px 20px 15px;
  }
}

@media (max-width: 360px) {
  .input-main {
    bottom: 28px;
  }
}

@media (max-width: 260px) {
  .input-main {
    bottom: 52px;
  }
}
