.user-main {
  position: absolute;
  background-color: var(--user-details-bg);
  width: 350px;
  height: 330px;
  z-index: 6;
  border-radius: 24px;
  top: 80px;
  right: 20px;
  display: flex;
  flex-flow: nowrap column;
  align-items: center;
  padding: 15px;
  color: var(--user-details-font-color);
  box-shadow: var(--user-details-box-shadow);
  user-select: none;
  -webkit-user-select: none;
}

.user-main a {
  text-decoration: none;
  color: var(--user-details-font-color);
}

.user-data {
  width: 100%;
}

.userIcon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: 18px;
  margin-bottom: 5px;
}

.name {
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
}

.signout {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-flow: nowrap row;
  gap: 10px;
  align-items: center;
  background-color: var(--signout-bg);
  padding: 12px 20px;
  border-radius: 24px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.signout:hover {
  background-color: var(--signout-hover-bg);
}

.signout img {
  width: 25px;
  height: 25px;
}

.privacy {
  display: flex;
  flex-flow: nowrap row;
  gap: 6px;
  position: absolute;
  bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}

.privacy a {
  padding: 5px 5px;
  border-radius: 4px;
}

.privacy a:hover {
  background-color: var(--user-details-provacy-hover-bg);
}

.cross {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.cross:hover {
  background-color: var(--cross-hover-bg);
}

.cross img {
  width: 28px;
  height: 28px;
}

@media (max-width: 400px) {
  .user-main {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 0px;
    padding: 20px 15px;
  }

  .cross {
    top: 15px;
  }

  .privacy {
    bottom: 20px;
  }
}
