
@tailwind base;
@tailwind components;
@tailwind utilities;
*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --bard-color-brand-text-gradient-stop-1: #4285f4;
  --bard-color-brand-text-gradient-stop-1-rgb: rgb(66, 133, 244);
  --bard-color-brand-text-gradient-stop-2: #9b72cb;
  --bard-color-brand-text-gradient-stop-2-rgb: rgb(155, 114, 203);
  --bard-color-brand-text-gradient-stop-3: #d96570;
  --bard-color-brand-text-gradient-stop-3-rgb: rgb(217, 101, 112);
  --bard-color-surface: #131314;
}

[data-theme="dark"] {
  --chat-section-bg: #131314;

  --logo-font-color: #caccce;
  --header-plus-bg: #282a2c;
  --header-login-border: rgba(0, 0, 0, 0.616);
  --header-login-hover-bg: #333537;
  --header-user-icon-bg: #1c1c1f;
  --header-user-icon-clicked-bg: #252628;

  --userprompt-text-span-color: #d96570;

  --new-chat-h2-color: #444746;

  --prompt-bg: #1e1f20;
  --prompt-bg-hover: #333537;
  --prompt-bg-selected: #3b4456;
  --prompt-font-color: rgb(227, 227, 227);
  --prompt-icon-bg: #131314;

  --input-font-color: rgb(255, 255, 255);
  --input-bg: #282a2c;
  --input-focus-bg: #1e1f20;
  --input-placeholder-font-color: #bdc1c6;

  --warning-text-color: #c4c7c5;

  --advance-gimini-gb: #282a2c;
  --advance-gimini-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.096);
  --advance-gimini-h4-color: #ffffff;
  --gemini-p-color: #e3e3e3;
  --gemini-advance-p-color: #a0a0a0;
  --advance-button-bg: #1b1b1b;
  --advance-button-font-color: white;
  --advance-button-hover-bg: #131313;

  --sidebar-bg-color: #1e1f20;
  --menuicon-hover-bg: #393b3d;
  --newchat-bg: #282a2c;
  --new-chat-hover-bg: #393b3d;
  --new-chat-new-bg: #1a1a1c;
  --new-chat-new-opacity: 0.4;
  --icon-p-color: rgb(227, 227, 227);
  --plus-icon-before-font-color: white;
  --plus-icon-before-bg-color: #3c4043;
  --recent-chat-font-color: #c2e7ff;
  --recent-active-chat-bg: #004a77;
  --show-more-font-color: #c4c7c5;
  --three-dot-hover-bg: rgba(0, 0, 0, 0.342);
  --recent-chat-hover-bg: #282a2c;
  --recent-chat-active-bg: #043d61;
  --help-before-font-color: white;
  --help-before-bg: #3c4043;
  --help-hover-bg: #303131;
  --upgrade-gimini-settings-bg: #47494a;
  --upgrade-gimini-p: #ffffff;
  --location-font-color: #a8c7fa;
  --location-name-color: #ffffff;
  --dot-color: #8e918f;

  --setting-main-bg: #424242;
  --setting-main-box-shadow: 0px 0px 40px 15px rgb(0 0 0 / 25%);
  --settings-h4-color: rgb(255, 255, 255);
  --settings-option-hover-bg: #515151;
  --settings-p-color: rgb(255, 255, 255);

  --bg-focus-pc: rgba(0, 0, 0,  51%);
  --bg-focus-mobile: rgba(0, 0, 0, 0.274);

  --chat-font-color: #e3e3e3;

  --code-bg: #1e1f20;
  --code-text-color: #d8d6d6;
  --code-type-color: #22d3eb;
  --code-title-color: #18cea0;
  --code-code-color: #f52358e8;
  --code-keyword: #d1ad48;
  --code-tag: #ffffffc7;
  --code-name: #cb63b7;
  --code-attr: #cd4545;

  --user-details-bg: #282a2c;
  --user-details-font-color: #dbdbdb;
  --signout-bg: #1b1b1b;
  --signout-hover-bg: #37393b;
  --cross-hover-bg: #37393b;
  --user-details-box-shadow: 0px 0px 10px 2px #03030360;
  --user-details-provacy-hover-bg: #37393b;
}

[data-theme="light"] {
  --chat-section-bg: #ffffff;

  --userprompt-text-span-color: #9c72cb;

  --logo-font-color: #5f6368;
  --header-plus-bg: #e9eef6;
  --header-login-border: rgba(51, 219, 248, 0.096);
  --header-login-hover-bg: #dde3ea;
  --header-user-icon-bg: #d4d4d4;
  --header-user-icon-clicked-bg: rgb(191, 196, 201);

  --new-chat-h2-color: #c4c7c5;

  --prompt-bg: #f0f4f9;
  --prompt-bg-hover: #dde3ea;
  --prompt-bg-selected: #d3e3fd;
  --prompt-font-color: #1f1f1f;
  --prompt-icon-bg: #ffffff;

  --input-font-color: 1f1f1f;
  --input-bg: #f0f4f9;
  --input-focus-bg: #e9eef6;
  --input-placeholder-font-color: #5f6368;

  --warning-text-color: #444746;

  --advance-gimini-gb: #e9eef6;
  --advance-gimini-box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.096);
  --advance-gimini-h4-color: #000000;
  --gemini-p-color: #2f3030;
  --gemini-advance-p-color: #9c9fa4;
  --advance-button-bg: #f8fafd;
  --advance-button-font-color: #000000;
  --advance-button-hover-bg: #eef0f3;

  --sidebar-bg-color:#f9f9f9; 
   /* #f0f4f9; */
  --menuicon-hover-bg: #ebebeb;
  /* #e9edf2; */
  --newchat-bg: #d4dae1;
  --new-chat-hover-bg: #cad0d8;
  --new-chat-new-bg: #e5eaf1;
  --new-chat-new-opacity: 0.35;
  --icon-p-color: #1f1f1f;
  --plus-icon-before-font-color: white;
  --plus-icon-before-bg-color: #3c4043;
  --recent-chat-font-color: #1f1f1f;
  --recent-active-chat-bg: #dfdfdf;
   /* #d3e3fd; */
  --show-more-font-color: #444746;
  --three-dot-hover-bg: #ffffff;
  --recent-chat-hover-bg: #dfdfdf;
  --recent-chat-active-bg: #c4d7f7;
  --help-before-font-color: white;
  --help-before-bg: #3c4043;
  --help-hover-bg: #f2f0f0;
  --upgrade-gimini-settings-bg: #d4dae1;
  --upgrade-gimini-p: #000015;
  --location-font-color: #0b97e2;
  --location-name-color: #1f1f1f;
  --dot-color: #747775;

  --setting-main-bg: #ffffff;
  --setting-main-box-shadow: 0px 0px 40px 15px rgb(0 0 0 / 25%);
  --settings-h4-color: #000000;
  --settings-option-hover-bg: #f5f5f5;
  --settings-p-color: #1f1f1f;

  --bg-focus-pc: rgba(0, 0, 0, 51%);
  --bg-focus-mobile: rgba(0, 0, 0, 0.068);

  --chat-font-color: #1f1f1f;

  --code-bg: #f0f4f9;
  --code-text-color: #333030;
  --code-type-color: #095ba8;
  --code-title-color: #089270;
  --code-code-color: #d60b3ee8;
  --code-keyword: #730ad6e0;
  --code-tag: #444141ef;
  --code-name: #cb63b7;
  --code-attr: #d43b3b;

  --user-details-bg: #e9eef6;
  --user-details-font-color: #302f2f;
  --signout-bg: #f8fafd;
  --signout-hover-bg: #dce1e8;
  --cross-hover-bg: #dce1e8;
  --user-details-box-shadow: 0px 0px 8px 4px #c8c8c8a1;
  --user-details-provacy-hover-bg: #dce1e8;
  --bg-focus-pc: rgba(0, 0, 0,  51%);
}

html {
  height: 100dvb;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100dvh;
  /* background-color: #131314; */
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w-full {
  width: 100% !important;
}
input:-webkit-autofill ~ label {
  transform: translate();
}