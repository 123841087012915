.loader-container {
  width: 100%;
  height: 4px;
  background-color: #045f9c67;
  overflow: hidden;
}

.loader-bar {
  height: 100%;
  width: 0;
  background-color: #3498db;
  animation: progressAnimation 1s infinite;
}

@keyframes progressAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
